@import '../../styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  margin-top: 40px;
  height: 100%;
}

.link {
  text-decoration: none;
  color: $color-accent;
  font-size: 24px;
  line-height: 1.1;
  display: flex;
  margin-top: auto;
}

.button {
  color: $color-accent;
  font-size: 24px;
  line-height: 1.1;
  display: flex;
  margin-top: auto;
  border: 0;
  background: transparent;
  padding: 0;

  &:hover {
    cursor: pointer;
    color: lighten($color-accent, 5%);
  }
}

.grey {
  color: $white;
  opacity: 0.8;
  & a {
    color: $white;
    opacity: 0.8;
  }
  &:hover {
    opacity: 1;
  }
}

.iconWrapper {
  margin-left: 20px;
}

@media only screen and (max-width: $small-laptop-breakpoint) {
  .link,
  .button {
    font-size: 20px;
  }
}
