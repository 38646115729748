@import '../../styles/variables';

.overlay {
  position: relative;
  height: 100vh;
}

.loaderOverlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-dark-blue;
  opacity: 0;
  transition: opacity ease 200ms;

  &.in.visible {
    opacity: 1;
  }
}

.ldsRipple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ldsRipple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ldsRipple div:nth-child(2) {
  animation-delay: -0.5s;
}

.heroVideo {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -11;
  overflow: hidden;

  & > video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    z-index: 0;
    background-image: linear-gradient(
      180deg,
      rgba(8, 13, 28, 0),
      rgba(8, 13, 28, 0.4) 80%,
      #080d1c
    );
  }
}

.heroVideo.darkBg {
  &:before {
    background-image: linear-gradient(
      180deg,
      rgba(8, 13, 28, 0),
      rgba(8, 13, 28, 0.4) 80%,
      #080d1c
    );
  }
}

.heroTestimonials {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  margin: 0 auto;
  bottom: 60px;
  left: 80px;
  right: 80px;
  z-index: 2;

  .heroTextMobileContainer {
    display: none;
  }

  .videoProgressContainer {
    display: flex;

    & .videoProgress:first-child {
      margin-right: 60px;
    }
  }

  & .videoProgress {
    color: $white;
    opacity: 1;
  }

  & .videoProgress .heading {
    font-size: 30px;
    line-height: 40px;
    margin-top: 15px;
    font-weight: 400;
  }

  & .videoProgress .description {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
  }

  & .videoProgress.disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  .buttonsContainer {
    display: flex;
    & > :first-child {
      margin-right: 20px;
    }
  }
}

.progressBar {
  -webkit-appearance: none;
  border: none;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  height: 5px;
  width: 240px;

  &::-webkit-progress-bar {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
  }

  &::-webkit-progress-value {
    border-radius: 20px;
    background-color: $white;
  }
}

.heroText {
  width: 700px;
  color: $white;
  font-weight: 500;
  font-size: 60px;
  line-height: 1.26;
  position: absolute;
  top: 20vh;
  display: inline-block;

  &:before {
    content: '';
    position: absolute;
    background-color: $color-blue;
    left: 0;
    top: 20px;
    bottom: 20px;
    right: 97%;
  }

  & span {
    border-radius: 20px;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    padding-left: 20px;
    padding-right: 20px;
    transition: width 300ms, height 300ms;
    background-color: $color-blue;
  }
}

.heroText.right {
  right: 8%;
}

.heroText.left {
  left: 10%;
}

.fullVideoBtn {
  font-family: $fontFamily;
  padding: 14px 27px 14px 16px;
  display: flex;
  align-items: center;
  background-color: $color-accent;
  outline: none;
  border: 0;
  border-radius: 30px;
  cursor: pointer;
  font-size: 20px;
  line-height: 40px;
  color: $white;
  max-height: 60px;
  transition: ease 400ms;

  &:hover {
    background-color: lighten($color-accent, 5%);
  }

  & > :first-child {
    margin-right: 12px;
  }
}

.volumeBtn {
  width: 60px;
  height: 60px;

  & circle {
    transition: ease 400ms;
    fill: $white;
  }
  & path {
    transition: ease 400ms;
    // fill: $color-blue;
  }
  &:hover {
    cursor: pointer;

    path {
      fill: lighten($color-blue, 25%);
    }
  }
}

.fallbackPlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  &:hover {
    cursor: pointer;

    & svg path {
      fill: $color-accent;
    }
  }

  & svg {
    width: 90px;
    height: 90px;
  }
}

@media only screen and (max-width: $laptop-breakpoint) {
  .heroTestimonials {
    left: 50px;
    right: 50px;

    & .videoProgressContainer {
      & .videoProgress:first-child {
        margin-right: 30px;
      }
    }

    & .videoProgress {
      & .progressBar {
        width: 125px;
      }

      & .heading {
        font-size: 18px;
        line-height: 24px;
      }

      & .description {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .heroText {
    font-size: 56px;
  }

  .heroText.right {
    right: 6%;
  }

  .heroText.left {
    left: 50px;
  }
}

@media only screen and (max-width: $small-laptop-breakpoint) {
  .heroText {
    width: 55%;
    font-size: 42px;

    &:before {
      right: 97%;
    }
  }

  .overlay {
    max-height: 700px;
  }

  .heroText.right {
    right: 3%;
  }
}

@media only screen and (max-width: $mobile-xl-breakpoint) {
  .overlay {
    max-height: 580px;
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .heroVideo {
    background-color: $color-dark-blue;
    & > video {
      height: 70vh;
      max-height: 480px;
    }

    &:before {
      background-image: linear-gradient(
        180deg,
        rgba(8, 13, 28, 0),
        rgba(8, 13, 28, 0.4) 80%,
        #080d1c
      );
    }

    & .heroTextContainer {
      display: none;
    }
  }
  .heroVideo.darkBg {
    &:before {
      background-image: linear-gradient(
        180deg,
        rgba(8, 13, 28, 0) 0%,
        rgba(8, 13, 28, 1) 70%
      );
    }
  }

  .fallbackPlay {
    top: 35vh;
  }

  .heroTestimonials {
    flex-direction: column;
    align-items: flex-start;
    bottom: 30px;
    height: 45vh;
    max-height: 280px;
    left: 25px;
    right: 25px;

    & .heroTextMobileContainer {
      display: block;

      & .heroText {
        top: 0;
        font-size: 24px;
        width: 100%;
        left: 0;
        right: 0;

        & span {
          background-color: transparent;
          padding: 0;
        }

        &:before {
          display: none;
        }
      }
    }

    & .videoProgressContainer {
      justify-content: space-between;
      width: 100%;
      position: absolute;
      bottom: 70px;

      & .videoProgress {
        flex: 1;

        progress {
          width: 100%;
        }

        & .heading {
          font-size: 16px;
        }

        & .description {
          font-size: 13px;
        }
      }

      & .videoProgress:first-child {
        margin-right: 30px;
      }
    }

    & .buttonsContainer {
      justify-content: space-between;
      flex-direction: row-reverse;
      width: 100%;

      & .volumeBtn {
        width: 45px;
        height: 45px;
        margin-right: 0;
      }
      & .fullVideoBtn {
        max-height: 45px;
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
}

@media only screen and (max-width: $mobile-lg-breakpoint) {
  .heroVideo {
    background-color: $color-dark-blue;

    & > video {
      height: 50vh;
    }
  }
  .fallbackPlay {
    top: 25vh;
  }
  .overlay {
    height: 80vh;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .heroVideo {
    & .heroText {
      top: 25%;
      font-size: 28px;
      width: unset;

      &:before {
        right: 96%;
      }
    }
  }
}

@media only screen and (max-width: $mobile-xs-breakpoint) {
  .heroTestimonials {
    left: 10px;
    right: 10px;
    max-height: 240px;

    & .heroTextMobileContainer {
      & .heroText {
        font-size: 18px;
      }
    }

    & .videoProgressContainer {
      & .videoProgress {
        & .description {
          font-size: 11px;
          letter-spacing: 0.3px;
        }
      }
    }
  }
  .heroText.left,
  .heroText.right {
    left: 10px;
    right: 10px;
  }
}
