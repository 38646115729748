@import '../../styles/variables';

.blogPreviewsContainer {
  background-image: linear-gradient(123deg, #002295 0%, #a828ce 100%);
  padding-bottom: 110px;
  padding-top: 200px;
  position: relative;

  & .content {
    & .heading {
      font-size: 80px;
      line-height: 90px;
      margin-bottom: 25px;
      text-align: center;
      color: $white;
    }
  }
}

.blogPostPreviewLink {
  text-decoration: none;
}

.blogPostPreviewContainer {
  max-width: 1060px;
  width: 100%;
  display: flex;
  margin: 0 auto;
  background-color: $white;
  border-radius: 40px;
  overflow: hidden;
  max-height: 600px;

  & .blogPostPreviewImage {
    position: relative;
    width: 50%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .blogPostPreviewTopic {
    background-color: $color-blue;
    color: $white;
    border-radius: 20px;
    width: 90px;
    height: 40px;
    font-size: 20px;
    line-height: 40px;
    text-align: center;

    position: absolute;
    top: 20px;
    left: 30px;
    z-index: 1;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  & .image {
    height: 100%;
  }

  & .fullNameAndPos {
    position: absolute;
    z-index: 1;
    bottom: 40px;
    left: 50px;
    color: $white;

    & .name {
      font-size: 30px;
      line-height: 40px;
    }

    & .position {
      font-size: 18px;
      line-height: 30px;
    }
  }

  & .blogPostPreviewContent {
    flex: 1;
    padding: 40px 50px 40px 50px;
    color: $color-dark-blue;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .topPart {
      overflow: hidden;
      text-overflow: ellipsis;

      & .previewHeading {
        font-size: 40px;
        line-height: 50px;
        font-weight: 500;
        margin-bottom: 24px;
      }
    }

    & .previewDescription {
      font-size: 24px;
      line-height: 32px;
    }

    & .actionBtn {
      font-size: 24px;
      color: $color-accent;
      text-decoration: none;
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-top: 20px;

      & .arrowRight {
        margin-left: 10px;
      }

      &:hover {
        color: lighten($color-accent, 10);
      }
    }
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .blogPreviewsContainer {
    & .content {
      & .heading {
        font-size: 56px;
        line-height: 64px;
      }
    }
  }
  .blogPostPreviewContainer {
    & .blogPostPreviewContent {
      & .topPart {
        & .previewHeading {
          font-size: 32px;
          line-height: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: $laptop-breakpoint) {
  .blogPreviewsContainer {
    & .content {
      & .heading {
        font-size: 68px;
        line-height: 80px;
      }
    }
  }
}

@media only screen and (max-width: $small-laptop-breakpoint) {
  .blogPostPreviewContainer {
    flex-direction: column;
    max-height: unset;

    & .blogPostPreviewContent {
      padding: 40px 30px 50px 30px;
    }

    .actionBtn {
      margin-top: 40px;
    }

    .blogPostPreviewImage {
      width: 100%;

      .image {
        height: 400px;
      }
    }

    .fullNameAndPos {
      left: 30px;
    }
  }
}

@media only screen and (max-width: $mobile-xl-breakpoint) {
  .blogPreviewsContainer {
    & .content {
      & .heading {
        font-size: 43px;
        line-height: 56px;
      }
    }
  }
  .blogPostPreviewContainer {
    .blogPostPreviewImage {
      width: 100%;

      .image {
        height: 320px;
      }

      .blogPostPreviewTopic {
        left: 20px;
      }
    }

    .fullNameAndPos {
      left: 20px;

      .name {
        font-size: 20px;
      }
    }

    & .blogPostPreviewContent {
      padding: 30px 20px;

      .actionBtn {
        font-size: 20px;
      }

      & .topPart {
        & .previewHeading {
          font-size: 28px;
          line-height: 36px;
        }

        .previewDescription {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-lg-breakpoint) {
  .blogPreviewsContainer {
    & .content {
      & .heading {
        font-size: 40px;
        line-height: 50px;
      }
    }
  }
}
