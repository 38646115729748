@import '../../styles/variables';

.techTransformContainer {
  padding: 80px 0;
  padding-bottom: 0;
  margin-bottom: -100px;
  background-color: rgba(8, 13, 28, 100);
  transition: background-color 300ms ease;

  .textContainer {
    margin: 0 auto;
    max-width: 75%;
    margin-bottom: 100px;
  }

  .textContainer .heading {
    font-size: 80px;
    line-height: 96px;
    background-color: $color-accent;
    background-image: $accent-gradient;
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    margin-bottom: 50px;
  }

  .textContainer .highlightedText {
    border-radius: 25px;
    background-color: $color-accent;
    padding: 0 14px;
  }

  .textContainer .description {
    & p {
      font-size: 40px;
      line-height: 48px;
      color: $white;
    }
    & strong {
      font-weight: 400;
      border-radius: 25px;
      background-color: $color-accent;
      padding: 0 14px;
    }
  }

  .cardsContainer.visible {
    transform: translateY(0);
    opacity: 1;
  }

  .cardsContainer {
    position: relative;
    z-index: 2;
    transform: translateY(200px);
    transition: all 1s ease;
    opacity: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    row-gap: 120px;

    .card {
      box-sizing: border-box;
      background-color: $white;
      border-radius: 45px;
      color: $color-dark-blue;
      display: flex;
      flex-direction: column;
      width: calc(50% - 60px);

      & .cardTextContainer {
        display: flex;
        flex-direction: column;
        padding: 40px 50px 68px 50px;
      }

      & .cardTextContainer .description.marginTop {
        margin-top: 16px;
      }

      & .cardTextContainer .contentWrapper h4 {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 32px;
      }

      & .cardTextContainer .contentWrapper p {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      & .cardTextContainer .link {
        display: flex;
        align-items: center;
        margin-top: 40px;
        height: 100%;

        & .arrow {
          margin-left: 20px;
        }

        & a {
          text-decoration: none;
          color: $color-accent;
          font-size: 24px;
          line-height: 1.1;
          display: flex;
          margin-top: auto;
        }
      }

      & .cardTextContainer .link {
        & button {
          color: $color-accent;
          font-size: 24px;
          line-height: 1.1;
          display: flex;
          margin-top: auto;
          border: 0;
          background: transparent;
          padding: 0;

          &:hover {
            cursor: pointer;
            color: lighten($color-accent, 5%);
          }
        }
      }

      & .cardTextContainer .link.grey {
        color: $white;
        opacity: 0.8;
        & a {
          color: $white;
          opacity: 0.8;
        }

        &:hover {
          opacity: 1;
        }
      }

      & .illustration .imageWrapper {
        border-top-left-radius: 45px;
        border-top-right-radius: 45px;
        transform: translateZ(0);
        object-fit: cover;
      }
    }

    .card.horizontal {
      width: 100%;
      flex-direction: row-reverse;
      justify-content: space-between;

      & .cardTextContainer {
        max-width: 45%;
      }

      & .illustration {
        display: flex;
        flex: 1 0;
        justify-content: flex-end;
        align-items: stretch;
        max-width: calc(50% - 60px);
      }

      & .illustration .imageWrapper {
        border-top-left-radius: 0;
        border-top-right-radius: 45px;
        border-bottom-right-radius: 45px;
        width: 100%;
        transform: translateZ(0);
      }
    }

    .card.accent {
      background-color: $color-blue;
      color: $white;
    }
  }
}

@media only screen and (max-width: $laptop-breakpoint) {
  .techTransformContainer {
    padding: 80px 0;
    padding-bottom: 0;

    .textContainer {
      max-width: 85%;
    }

    .textContainer .description {
      & p {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .textContainer .contentWrapper h4 {
      font-size: 60px;
      line-height: 72px;
    }

    .textContainer .contentWrapper p {
      font-size: 32px;
      line-height: 40px;
    }

    .textContainer .heading {
      font-size: 68px;
      line-height: 80px;
    }

    .cardsContainer {
      .card {
        & .cardTextContainer .contentWrapper h4 {
          font-size: 40px;
          line-height: 48px;
          margin-bottom: 24px;
        }

        & .cardTextContainer .contentWrapper p {
          font-size: 20px;
          line-height: 24px;
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
  }
}

@media only screen and (max-width: $small-laptop-breakpoint) {
  .techTransformContainer {
    padding: 60px 0;
    padding-bottom: 0;

    .textContainer {
      max-width: 100%;
      padding: 0 20px;
    }

    .cardsContainer {
      row-gap: 60px;
      .card {
        width: calc(50% - 30px);
        .cardTextContainer {
          padding: 40px 30px 50px;
        }

        & .cardTextContainer .contentWrapper h4 {
          font-size: 32px;
          line-height: 32px;
        }

        & .cardTextContainer .contentWrapper p {
          font-size: 20px;
          line-height: 26px;
        }
      }

      .card.horizontal {
        & .illustration {
          max-width: calc(50% - 30px);
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .techTransformContainer {
    padding: 40px 0;
    padding-bottom: 0;

    .textContainer {
      padding: 0 20px;
    }

    .textContainer .heading {
      font-size: 56px;
      line-height: 64px;
    }

    .textContainer .description {
      font-size: 24px;
      line-height: 29px;
    }

    .cardsContainer {
      .card {
        width: 100%;
        & .cardTextContainer {
          padding: 40px 30px 50px;
        }

        & .cardTextContainer .heading {
          font-size: 24px;
          line-height: 29px;
        }

        & .cardTextContainer .description {
          font-size: 16px;
          line-height: 20px;
        }

        &.horizontal {
          flex-direction: column;

          .illustration {
            max-width: 100%;
            .imageWrapper {
              border-top-left-radius: 45px;
              border-top-right-radius: 45px;
              border-bottom-right-radius: 0px;
              border-bottom-left-radius: 0px;
            }
          }

          .cardTextContainer {
            max-width: 100%;
            width: unset;
          }
        }
      }
      .card.horizontal {
        & .illustration {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .techTransformContainer {
    .textContainer .heading {
      font-size: 43px;
      line-height: 54px;
    }
  }
}
@media only screen and (max-width: $mobile-lg-breakpoint) {
  .techTransformContainer {
    .textContainer .description {
      & p {
        font-size: 28px;
        line-height: 40px;
      }
    }
    .textContainer .heading {
      font-size: 40px;
      line-height: 50px;
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .techTransformContainer {
    .textContainer .description {
      & p {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}
