@import '../../styles/variables';

.youtubeVideoContent {
  max-width: 1280px;
  max-height: 720px;
  width: 100%;
  height: 100%;
  & iframe {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .youtubeVideoContent {
    max-width: 640px;
    max-height: 360px;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .youtubeVideoContent {
    max-width: 320px;
    max-height: 180px;
  }
}
