.portableTextStyle {
  font-size: 20px;
  line-height: 2;
  color: inherit;

  * {
    color: inherit;
  }

  h2 {
    margin: 30px 0 16px 0;
  }

  p {
    line-height: 32px;
  }
}
