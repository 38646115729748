@import '../../styles/variables';

button:hover ~ .blobContainer {
  .blob {
    background: $color-blue;
    width: 300px;
    height: 300px;
  }
}

.blobContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: transparent;
  overflow: hidden;
  transition: all 400ms ease-in-out;
  transform: scale(2);
  opacity: 0;
  z-index: -10;

  & .blob {
    transform: translate(-50%, -50%);
    transition: 450ms ease-out;
    filter: blur(50px);
    will-change: transform;
    position: absolute;
    width: 600px;
    height: 600px;

    background: $color-accent;
    opacity: 0.5;
    z-index: -10;
    border-radius: 50% 45% 60% 35%;
  }
  & .blob:first-child {
    transition: 500ms ease-out;
    background: $color-blue;
    width: 800px;
    height: 800px;
    opacity: 0.5;
    border-radius: 20% 55% 60% 60%;
    filter: blur(80px);
  }
  & .blob:last-child {
    background: $color-accent;
    transition: 350ms ease-out;
    filter: blur(40px);
    width: 300px;
    height: 300px;
    opacity: 0.9;
  }
}

.blobContainer.in.visible {
  opacity: 1;
  transform: scale(1);
}

@media only screen and (max-width: $small-laptop-breakpoint) {
  .blobContainer {
    display: none;
  }
}
