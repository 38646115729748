@import '../../styles/variables';

.logosContainer {
  padding: 60px 0;
  flex-wrap: wrap;
  background-color: $white;

  .logosContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: $small-laptop-breakpoint) {
  .logosContainer {
    .logosContent {
      justify-content: center;

      & .clientLogo {
        padding: 20px;
      }
    }
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .logosContainer {
    .logosContent {
      justify-content: center;

      & svg {
        margin: 20px;
      }
    }
  }
}
