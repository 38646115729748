@import '../../styles/variables';

@import '../../components/blobCursor/blobCursor.module.scss';

@mixin review-styles {
  color: $white;

  & p {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 30px;
  }
  & span {
    color: $color-grey;
    font-size: 16px;
    line-height: 30px;
  }
  & img {
    border-radius: 50%;
  }

  & .solImage {
    margin-bottom: 20px;
    width: 80px;
    height: 80px;
  }
}

.architecturalSectionContainer {
  padding-top: 100px;

  .container {
    position: relative;
    z-index: 1;
  }

  & .heading {
    font-size: 80px;
    line-height: 96px;
    background-color: $color-accent;
    background-image: $accent-gradient;
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    margin-bottom: 50px;
  }

  & .description {
    font-size: 40px;
    line-height: 48px;
    color: $white;
  }

  .reviewRow {
    color: $white;
    margin-top: 55px;
    display: flex;
    justify-content: space-between;

    & .limitedSpot {
      font-size: 40px;
      line-height: 48px;
      width: calc(60% - 30px);

      & .claimSpotContainer {
        display: flex;
        align-items: center;
        margin-top: 60px;

        & .button {
          border-radius: 45px;
          padding: 20px 40px;
          font-size: 30px;
          line-height: 36px;
          white-space: nowrap;
          border: none;
          background: $accent-gradient;
          color: $white;

          &:hover {
            background: $accent-gradient-hover;
            cursor: pointer;
          }
        }

        & .downloadAuditBtn {
          font-size: 30px;
          line-height: 36px;
          color: $color-accent;
          padding: 10px 20px;
          margin-left: 20px;
          background: none;
          border: none;

          &:hover {
            color: lighten($color-accent, 5%);
            cursor: pointer;
          }
        }
      }
    }

    & .limitedSpot p:first-child {
      margin-bottom: 24px;
    }

    & .review {
      @include review-styles;
      width: calc(40% - 30px);
    }
  }
}

@media only screen and (max-width: $small-laptop-breakpoint) {
  .architecturalSectionContainer {
    & .heading {
      font-size: 68px;
      line-height: 80px;
    }
    & .description {
      font-size: 32px;
      line-height: 40px;
    }
    .reviewRow {
      & .limitedSpot {
        & .claimSpotContainer {
          & .button {
            font-size: 20px;
            line-height: 32px;
          }

          & .downloadAuditBtn {
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
  }
}

// ... (rest of the media queries remain unchanged since they are based on the above changes)

@media only screen and (max-width: $tablet-breakpoint) {
  .architecturalSectionContainer {
    & .heading {
      font-size: 56px;
      line-height: 64px;
    }
    & .reviewRow {
      flex-direction: column;

      & .limitedSpot {
        width: 100%;
        margin-bottom: 40px;

        & .claimSpotContainer {
          & .button {
            font-size: 26px;
            line-height: 32px;
          }

          & .downloadAuditBtn {
            font-size: 26px;
            line-height: 32px;
          }
        }
      }

      & .review {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: $mobile-xl-breakpoint) {
  .architecturalSectionContainer {
    & .reviewRow {
      & .limitedSpot {
        & .claimSpotContainer {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 40px;

          & .button {
            width: 100%;
          }

          & .downloadAuditBtn {
            width: 100%;
            margin-top: 20px;
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-lg-breakpoint) {
  .architecturalSectionContainer {
    & .heading {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 30px;
    }
    & .description {
      font-size: 28px;
      line-height: 40px;
    }
    & .reviewRow {
      & .limitedSpot {
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 0;
      }

      & .review p {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
}

@media only screen and (max-width: $mobile-xs-breakpoint) {
  .architecturalSectionContainer {
    & .heading {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 30px;
    }
    & .description {
      font-size: 24px;
      line-height: 32px;
    }

    & .reviewRow {
      & .limitedSpot {
        font-size: 24px;
        line-height: 32px;
      }

      & .review p {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
}
